*{
    box-sizing: border-box;
    margin:0;
    padding:0;
    
  }
  h1,h2,h3{
    font-family: 'Montserrat', sans-serif;
  }
  p,span{
    font-family:'Raleway', sans-serif;
    
  }

  .icons{
    height: 50px;
    width:40px;
    margin:0;
    padding:0;
  }